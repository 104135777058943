<template>
  <form>
    <v-row class="py-0">
      <v-col class="py-0" cols="12" sm="12" md="11" lg="11">
        <form-edit
          :form="saleInvoice"
          :contacts="contacts"
          :accounts="accounts"
          ref="formEdit"
          v-if="!edit"
        ></form-edit>
        <detail :id="id" :form="saleInvoice" :contacts="contacts" v-if="edit"></detail>
      </v-col>
      <v-col cols="12" sm="12" md="1">
        <v-row class="py-0 mb-2" v-if="!edit">
          <v-col cols="12" class="py-0">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn color="success" v-on="on" @click="updateItem">
                  <v-icon>
                    mdi-content-save
                  </v-icon>
                </v-btn>
              </template>
              <span>Save</span>
            </v-tooltip>
          </v-col>
        </v-row>
        <v-row class="py-0 mb-2" v-if="edit">
          <v-col cols="12" class="py-0">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn color="success" v-on="on" @click="printJournal">
                  <v-icon>
                    mdi-printer
                  </v-icon>
                </v-btn>
              </template>
              <span>Journal</span>
            </v-tooltip>
          </v-col>
        </v-row>
        <v-row class="py-0 mb-2" v-if="edit">
          <v-col cols="12" class="py-0">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn color="warning" v-on="on" @click="routeToPrintPreview">
                  <v-icon>
                    mdi-printer
                  </v-icon>
                </v-btn>
              </template>
              <span>Print Preview</span>
            </v-tooltip>
          </v-col>
        </v-row>
        <v-row
          class="py-0 mb-2"
          v-if="edit && saleInvoice.percentageUpTo === 100 && !saleInvoice.linkDo"
        >
          <v-col cols="12" class="py-0">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn color="success" v-on="on" @click="addDo">
                  <v-icon>
                    mdi-link
                  </v-icon>
                </v-btn>
              </template>
              <span>Link DO</span>
            </v-tooltip>
          </v-col>
        </v-row>
        <v-row class="py-0 mb-2" v-if="edit && !saleInvoice.fp">
          <v-col cols="12" class="py-0">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn color="success" v-on="on" @click="exportPajak">
                  <v-icon>
                    mdi-download
                  </v-icon>
                </v-btn>
              </template>
              <span>Export Pajak</span>
            </v-tooltip>
          </v-col>
        </v-row>
        <v-row class="py-0 mb-2" v-if="edit && saleInvoice.status === 'Outstanding'">
          <v-col cols="12" class="py-0">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn color="success" v-on="on" @click="editItem">
                  <v-icon>
                    mdi-pencil
                  </v-icon>
                </v-btn>
              </template>
              <span>Edit</span>
            </v-tooltip>
          </v-col>
        </v-row>
        <v-row class="py-0 mb-2" v-if="edit && saleInvoice.status === 'Outstanding'">
          <v-col cols="12" class="py-0">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn color="error" v-on="on" @click.stop="deleteDialog">
                  <v-icon>
                    mdi-cancel
                  </v-icon>
                </v-btn>
              </template>
              <span>Delete</span>
            </v-tooltip>
          </v-col>
        </v-row>
        <v-row class="py-0 mb-2" v-if="!edit">
          <v-col cols="12" class="py-0">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn color="error" v-on="on" @click="cancel">
                  <v-icon>
                    mdi-close
                  </v-icon>
                </v-btn>
              </template>
              <span>Close</span>
            </v-tooltip>
          </v-col>
        </v-row>
        <v-row class="py-0 mb-2" v-if="edit">
          <v-col cols="12" class="py-0">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn color="primary" v-on="on" @click="back">
                  <v-icon>
                    mdi-arrow-left
                  </v-icon>
                </v-btn>
              </template>
              <span>Back</span>
            </v-tooltip>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <dialog-cancel
      :dialog="dialog"
      :invoiceNumber="saleInvoice.invoiceNumber"
      @cancel="cancelInvoice('')"
      @cancelInvoice="cancelInvoice('invoice')"
      @close="close"
    ></dialog-cancel>
    <dialog-remark
      :dialog="dialogRemark"
      :form="formRemark"
      @save="updateRemark"
      @close="close"
    ></dialog-remark>
    <dialog-link-do
      :dialog="dialogLinkDo"
      :deliveryOrders="deliveryOrders"
      @save="linkDo"
      @close="close"
    ></dialog-link-do>
  </form>
</template>

<script>
import Detail from "./DetailSaleInvoice";
import FormEdit from "./FormSaleInvoice";
import DialogCancel from "@/components/DialogCancel";
import { mapState } from "vuex";
import fileDownload from "js-file-download";
import DialogRemark from "@/components/DialogRemark";
import DialogLinkDo from "@/components/DialogLinkDo";

export default {
  name: "sale-invoice-view",
  components: {
    Detail,
    "form-edit": FormEdit,
    "dialog-cancel": DialogCancel,
    DialogLinkDo,
    DialogRemark,
  },
  props: {
    id: {
      type: Number,
      default: () => undefined,
    },
  },
  data: () => ({
    edit: true,
    dialog: false,
    dialogRemark: false,
    dialogLinkDo: false,
    accounts: [],
    deliveryOrders: [],
    saleInvoice: {},
    formRemark: {},
  }),
  computed: {
    ...mapState("external", ["customers", "contacts"]),
  },
  methods: {
    async getSaleInvoice() {
      const response = await this.$store.dispatch("saleInvoice/getById", this.id);
      if (response.status === 200) {
        this.saleInvoice = response.data;
        this.$store.dispatch("external/getContact", response.data.customerId);
      }
    },
    async getAccounts() {
      const response = await this.$store.dispatch("chartOfAccount/getAccounts");
      if (response.status === 200) {
        this.accounts = response.data;
      }
    },
    back() {
      this.$router.go(-1);
    },
    editItem() {
      this.edit = false;
    },
    updateItem() {
      this.$refs.formEdit.checkJournal();
    },
    deleteDialog() {
      this.dialog = true;
    },
    close() {
      this.dialog = false;
      this.dialogRemark = false;
      this.dialogLinkDo = false;
    },
    cancel() {
      this.getSaleInvoice();
      this.edit = true;
    },
    routeToPrintPreview() {
      this.$router.push({ name: "Print Preview Sale Invoice", params: { id: this.id } });
    },
    printJournal() {
      this.$router.push({ name: "Journal Sale Invoice", params: { id: this.id } });
    },
    async updateRemark(item) {
      this.$store.commit("SET_LOADER", true);
      await this.$store
        .dispatch("saleInvoice/updateRemark", item)
        .then(() => this.$store.commit("SET_LOADER", false))
        .catch(() => {
          this.$store.dispatch("toast", {
            type: "error",
            message: "Ada masalah dengan aplikasi, mohon hubungi admin aplikasi",
          });
          this.$store.commit("SET_LOADER", false);
        });
    },
    openRemark() {
      this.dialogRemark = true;
    },
    async exportPajak() {
      this.$store.commit("SET_LOADER", true);
      await this.$store
        .dispatch("saleDownPayment/exportPajak", { id: this.id, type: "Invoice" })
        .then(response => {
          this.$store.commit("SET_LOADER", false);
          fileDownload(
            response.data,
            "ExportPajakInvoice-" + this.saleInvoice.invoiceNumber + ".csv"
          );
        })
        .catch(() => {
          this.$store.commit("SET_LOADER", false);
          this.$store.dispatch("toast", {
            type: "error",
            message: "Ada masalah dengan aplikasi, mohon hubungi admin aplikasi",
          });
        });
    },
    async cancelInvoice(type) {
      this.$store.commit("SET_LOADER", true);
      await this.$store
        .dispatch("saleInvoice/cancelInvoice", { id: this.id, type: type })
        .then(() => this.$store.commit("SET_LOADER", false))
        .catch(() => {
          this.$store.commit("SET_LOADER", false);
          this.$store.dispatch("toast", {
            type: "error",
            message: "Ada masalah dengan aplikasi, mohon hubungi admin aplikasi",
          });
        });
    },
    addDo() {
      this.getDeliveryOrders();
      this.dialogLinkDo = true;
    },
    async linkDo(items) {
      this.$store.commit("SET_LOADER", true);
      await this.$store
        .dispatch("saleInvoice/linkDo", { id: this.id, body: items })
        .then(() => this.$store.commit("SET_LOADER", false))
        .catch(() => {
          this.$store.dispatch("toast", {
            type: "error",
            message: "Ada masalah dengan aplikasi, mohon hubungi admin aplikasi",
          });
          this.$store.commit("SET_LOADER", false);
        });
    },
    getDeliveryOrders() {
      this.saleInvoice.purchaseOrders.forEach(async x => {
        await this.$store
          .dispatch("external/getSaleDeliveryOrders", x.id)
          .then(response => {
            this.deliveryOrders = this.deliveryOrders.concat(response.data);
            this.$store.commit("SET_LOADER", false);
          })
          .catch(() => {
            this.$store.dispatch("toast", {
              type: "error",
              message: "Ada masalah dengan aplikasi, mohon hubungi admin aplikasi",
            });
            this.$store.commit("SET_LOADER", false);
          });
      });
    },
    getInitialData() {
      this.$store.commit("SET_LOADER", true);
      Promise.all([this.getAccounts(), this.getSaleInvoice()])
        .then(() => this.$store.commit("SET_LOADER", false))
        .catch(() => {
          this.$store.commit("SET_LOADER", false);
          this.$store.dispatch("toast", {
            type: "error",
            message: "Ada masalah dengan aplikasi, mohon hubungi admin aplikasi",
          });
        });
    },
  },
  mounted() {
    this.getInitialData();
  },
  beforeMount() {
    this.formRemark = {
      remark: "",
      type: "Invoice",
      transactionId: this.id,
    };
  },
};
</script>

<style></style>
